<template>
  <div class="home">
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(item, index) in banner" :key="index">
        <div class="bannerItem">
          <img :src="item.img" alt=""/>
          <div class="box">
            <p>{{ item.title1 }}</p>
            <span>{{ item.title2 }}</span>
            <p>{{ item.title3 }}</p>
            <div class="tips">
              <div v-for="(i, id) in item.tips" :key="id">{{ i }}</div>
            </div>
            <div class="btn" @click="toLogin">免费注册</div>
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>
    <div class="iconsBox">
      <div class="title">
        <div>中马互贸跨境综合服务</div>
        <p>无论中国企业，还是马来西亚企业，均可找到适合的服务。</p>
      </div>
      <div class="serverList">
        <div class="items" v-for="(item, index) in serverList" :key="index">
          <div>
            <img :src="item.img" alt=""/>
          </div>
          <p>{{ item.text }}</p>
        </div>
      </div>
    </div>
    <div id="backgroundDiv" class="iconsBox2">
      <div class="title">
        <div>动态要闻</div>
        <p>为中马贸易伙伴提供最新资讯，时刻掌握最新热点</p>
      </div>
      <el-card class="box-card" style="margin-top: 40px;">
        <el-row type="flex">
          <el-col :span="4">
            <div v-for="(item,index) in tabs2" :key=index
                 style="cursor: pointer" @click="handleTabClick(item,index)"
                 :class="{ 'active-tab': activeTabIndex === index }">
              <div style="display: flex;align-items: center;justify-content: center;line-height: 30px;">
                <!--                <img :src="activeTabIndex === index ? tabsIconList[index].img2 : tabsIconList[index].img1"-->
                <!--                     style="width: 20px; height: 20px;"/>-->
                <span :class="activeTabIndex === index? 'active-text':'active-text2'"
                      style="font-size: 0.1rem;">{{ item.dictLabel }}</span>
              </div>
            </div>
          </el-col>
          <el-col :span="20">
            <div v-for="(item, index) in rightList" :key="index"
                 style="display: flex;margin-bottom: 20px;margin-left: 10px">
              <!--              <div style="cursor: pointer" @click="lookNewsInfo(item)" v-if="item.pic">-->
              <!--                <img :src="item.pic" style="width: 50px;height: 28px;margin: 5px ;border-radius: 6px;" alt=""/>-->
              <!--              </div>-->
              <div
                  style="display: flex;flex-direction: column;align-items: flex-start;justify-content: center;line-height: 15px;margin-left: 5px;cursor: pointer"
                  @click="lookNewsInfo(item)">
                <div
                    style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;max-width:230px;font-size: 0.12rem;color: #2a50b7;text-align: left">
                  {{ item.webTitle }}
                </div>
                <div style="font-size: 0.1rem;color: #999999;text-align: left">发布时间： {{ item.newsTime }}</div>
                <div
                    style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;max-width:230px;font-size: 0.1rem;color: #999999;text-align: left">
                  {{ item.introduction }}
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-card>
    </div>
    <div class="serve">
      <div class="cardBox">
        <div class="title">
          <div>产品与服务</div>
          <p>为中马互贸易伙伴提供各类政策、贸易、金融的相关产品与配套服务</p>
        </div>
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" v-if="imageCapsule.length > 0">
          <van-swipe-item v-for="(item, index) in imageCapsule" :key="index">
            <div class="bannerItem">
              <img style="height: 50px" :src="item.adPic" @click="toLogin">
            </div>
          </van-swipe-item>
        </van-swipe>
        <div class="cardList">
          <div class="cardItem" v-for="(item, index) in productServices" :key="index">
            <div class="title" :style="`background-image: url(${cardList[index].img});`">
              <p>{{ item.title }}</p>
            </div>
            <div class="textBox">
              <p class="text">{{ item.summary }}</p>
              <div class="btn" @click="lookDetail(item)">查看详情</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tabsBox">
      <div class="titleBox">
        <div class="title">
          <p
              :class="item.name == activeName ? 'active' : ''"
              v-for="(item, index) in tabs"
              :key="index"
              @click="tabClick(item.name)"
          >
            {{ item.name }}
          </p>
        </div>
      </div>
      <div
          class="tabsContent"
          v-for="(item, index) in tabs"
          :key="index"
          v-show="item.name == activeName"
      >
        <img :src="item.img" alt=""/>
        <div v-for="(i, id) in item.des" :key="id">
          <p>{{ i.title }}</p>
          <span>{{ i.text }}</span>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="unit">
      <div class="title">
        <p>园区合作单位</p>
        <span>致力于为中国和马来西亚的企业提供更好的营商环境</span>
      </div>
      <div class="list">
        <div class="listItem" v-for="(item, index) in unitList" :key="index">
          <div>
            <img :src="item.img1" alt=""/>
            <p>{{ item.name1 }}</p>
            <span>{{ item.text1 }}</span>
          </div>
          <div>
            <img :src="item.img2" alt=""/>
            <p>{{ item.name2 }}</p>
            <span>{{ item.text2 }}</span>
          </div>
          <div>
            <img :src="item.img3" alt=""/>
            <p>{{ item.name3 }}</p>
            <span>{{ item.text3 }}</span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        width="80%">
      <div v-html="detailContent"></div>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogVisible = false">关 闭</el-button>
        <el-button type="primary" size="mini" @click="toLogin()">立即注册</el-button>
       </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageCapsule: [],
      banner: [
        {
          img: require("../assets/home/banner04.png"),
          title1: "马来西亚及东盟十国",
          title2: "全球企业信用报告查询",
          title3: "年销超80万份 覆盖230个国家",
          tips: ["付款/还款能力", "综合信用","实力评估", "···"],
        },
        {
          img: require("../assets/home/banner01.png"),
          title1: "为你对接",
          title2: "马来西亚及东盟十国",
          title3: "优质、可靠的生意资源",
          tips: ["政策服务", "金融服务", "认证服务", "信息服务", "···"],
        },
        {
          img: require("../assets/home/banner02.png"),
          title1: "为好的企业",
          title2: "找到对的钱",
          title3: "基于区块链的跨境金融服务",
          tips: ["中信银行", "中国银行", "马来亚银行", "联昌银行", "···"],
        },
        {
          img: require("../assets/home/banner03.png"),
          title1: "星火链网 x 跨境综合服务",
          title2: "国家区块链基础设施赋能",
          title3: "基于新基建的跨境综合服务",
          tips: ["标准互通", "贸易互通", "产业互通", "人流互通", "···"],
        },
      ],
      serverList: [
        {
          img: require("../assets/home/icon1.png"),
          text: "政策便利",
        },
        {
          img: require("../assets/home/icon2.png"),
          text: "贸易便利",
        },
        {
          img: require("../assets/home/icon3.png"),
          text: "金融便利",
        },
        {
          img: require("../assets/home/icon4.png"),
          text: "认证便利",
        },
        {
          img: require("../assets/home/icon5.png"),
          text: "区块链支撑",
        },
        {
          img: require("../assets/home/icon6.png"),
          text: "合作共赢",
        },
      ],
      cardList: [
        {
          img: require("../assets/home/cardIcon1.png"),
          title: "外资企业注册绿色通道",
          text: "帮助马来西亚的企业，在中国迅速完成企业主体的设立程序。并提供完善、专业的金融服务。园区与各级政府部门、各大金融机构紧密协同，确保客户的信息资产安全、融资渠道畅通。",
        },
        {
          img: require("../assets/home/cardIcon2.png"),
          title: "私营企业注册绿色通道",
          text: "为有意与马来西亚进行贸易的中国企业提供高效注册通道，同时提供专业的渠道资源和供应链对接服务。企业可以快速获得必要的资质并与适合的境内外供应商建立联系。",
        },
        {
          img: require("../assets/home/cardIcon7.png"),
          title: "商务签证线上办理",
          text: "马来西亚商务签证线上办理，为企业提供贸易便利，打通专属绿色通道，相比其他第三方通道，中马互贸平台的签证服务具备更省时、更省力、更省心的特点。",
        },
        {
          img: require("../assets/home/cardIcon3.png"),
          title: "NRA账户快速设立",
          text: "便捷的NRA银行账户开设服务。简化开户过程、加速资金流动，满足企业在跨境交易、投资和资金管理方面的需求。园区与多家合作银行建立密切合作关系，确保开设NRA银行账户的高效和准确。",
        },
        {
          img: require("../assets/home/cardIcon4.png"),
          title: "FDI账户快速设立",
          text: "为用户提供快捷的FDI企业银行账户开设服务。帮助企业快速、顺利地开立FDI企业银行账户。园区提供全方位的咨询和指导，帮助企业了解不同银行的服务特点、政策要求和流程规定。",
        },
        {
          img: require("../assets/home/cardIcon5.png"),
          title: "马来西亚企业大数据",
          text: "全面的马来西亚企业信息查询，包括工商、税务、海关、征信评级等多个方面的信息数据，帮助客户更好地了解目标企业的运营情况，并且做出明智的商业决策。",
        },
      ],
      activeName: "政策便利服务",
      tabs: [
        {
          name: "政策便利服务",
          img: require("../assets/home/tabs1.png"),
          des: [
            {
              title: "提升效率：各类主体或账户手续办理专享绿色通道。",
              text: "更简单、更便捷，开户办理提供一站式服务。",
            },
            {
              title: "节省成本：园区入驻企业可享受最新的中马双方的政策扶持。",
              text: "中国及马来西亚双方政策扶持，助力企业节省资金。",
            },
            {
              title: "行业资讯：双边互贸新政个性化推送、精准触达。",
              text: "为企业决策提供最新的、可靠的行业及政策消息。",
            },
          ],
        },
        {
          name: "贸易便利服务",
          img: require("../assets/home/tabs2.png"),
          des: [
            {
              title: "融资便捷：NRA、FDI等投融资金融产品整合输出。",
              text: "跨境金融服务专家，提供专业的投融资方案并落地交付。",
            },
            {
              title: "资金增值：中马双边贸易居间对接，商会资源和资讯对接。",
              text: "助力企业对接产品及客户资源，匹配适合的贸易伙伴。",
            },
            {
              title: "投资：中国及马来西亚优质企业数据库。",
              text: "跨境投资产品整合，为企业提供适合的投资渠道。",
            },
          ],
        },
        {
          name: "国际认证服务",
          img: require("../assets/home/tabs3.png"),
          des: [
            {
              title: "权威认证：马来西亚国际清真认证（HALAL认证）。",
              text: "全球穆斯林国家认可的资质，平台客户享有快速办理渠道。",
            },
            {
              title: "验真服务：中国及马来西亚原产地认证核验。",
              text: "平台客户可享受中国及马来西亚原产地认证的验真服务。",
            },
            {
              title: "增信服务：其他中马贸易相关资质认证服务。",
              text: "各类中马贸易相关资质认证协助办理及资源匹配。",
            },
          ],
        },
        {
          name: "大数据服务",
          img: require("../assets/home/tabs4.png"),
          des: [
            {
              title: "马来西亚企业海量信息查询。",
              text: "马来西亚企业信息大数据查询，工商、税务、海关、征信数据。",
            },
            {
              title: "对接马来西亚CTOS马来西亚征信报告系统。",
              text: "马来西亚头部信贷资讯服务公司，受财政部信贷报告机构注册部监管。",
            },
            {
              title: "中马贸易相关增值服务。",
              text: "可定制中马贸易调研报告等周边增值服务。",
            },
          ],
        },
      ],
      unitList: [
        {
          img1: require("../assets/home/unit01.png"),
          name1: "MYEG集团",
          text1: "马来西亚电子政务服务商",
          img2: require("../assets/home/unit07.png"),
          name2: "新发地农产品",
          text2: "新发地农产品股份有限公司",
          img3: require("../assets/home/unit04.png"),
          name3: "中国银行",
          text3: "提供境内外企业金融服务",
        },
        {
          img1: require("../assets/home/unit03.png"),
          name1: "CardBiz",
          text1: "马来西亚支付服务商",
          img2: require("../assets/home/unit09.png"),
          name2: "星火链网",
          text2: "为平台提供区块链底层服务",
          img3: require("../assets/home/unit08.png"),
          name3: "Maybank",
          text3: "马来西亚最大的银行",
        },
        {
          img1: require("../assets/home/unit05.png"),
          name1: "Al Hamra集团",
          text1: "马来西亚头部认证机构",
          img2: require("../assets/home/unit02.png"),
          name2: "中信银行",
          text2: "提供境内外企业金融服务",
          img3: require("../assets/home/unit10.png"),
          name3: "CIMB BANK",
          text3: "联昌国际银行",
        },
      ],
      tabsIconList: [
        {
          img1: require("../assets/home/icon7.png"),
          img2: require("../assets/home/icon10.png"),
        },
        {
          img1: require("../assets/home/icon8.png"),
          img2: require("../assets/home/icon11.png"),
        },
        {
          img1: require("../assets/home/icon9.png"),
          img2: require("../assets/home/icon12.png"),
        }
      ],
      tabs2: [],
      tabValue: null,
      rightList: [],
      activeTabIndex: 0,
      productServices: [],
      title: null,
      detailContent: null,
      registrationLink: null,
      dialogVisible: false,
    };
  },
  created() {
    this.getAdvert();
    this.getTabs();
    this.getProductServices();
  },
  methods: {
    lookDetail(item) {
      console.log(item)
      this.title = item.title;
      this.detailContent = item.detailContent;
      this.registrationLink = item.registrationLink;
      this.dialogVisible = true;
    },
    getProductServices() {
      this.$axios
          .get("https://news.mycntrade.com/api/zm/queryProductServicesList")
          .then((res) => {
            if (res) {
              this.productServices = res.data
            }
          });
    },
    handleTabClick(item, index) {
      this.tabValue = item.dictValue;
      this.activeTabIndex = index;
      this.getList();
    },
    getTabs() {
      this.$axios
          .get("https://news.mycntrade.com/api/zm/queryTypeList")
          .then((res) => {
            if (res) {
              this.tabs2 = res.data;
              this.tabValue = this.tabs2[0].dictValue;
              this.getList();
            }
          });
    },
    getList() {
      let opt = new FormData();
      opt.append('type', this.tabValue);
      opt.append('pageNum', "1");
      opt.append('pageSize', "10");
      this.$axios
          .post("https://news.mycntrade.com/api/zm/queryHomePageList", opt)
          .then((res) => {
            if (res) {
              this.rightList = JSON.parse(res.data.data).splice(0, 3);
            }
          });
    },
    getAdvert() {
      this.$axios
          .post("https://platform.mycntrade.com/api/web/business/getAdvertData")
          .then((res) => {
            if (res.data.code === 0) {
              let data = res.data.data.rows
              if (data.length > 0) {
                for (let i in data) {
                  let item = data[i]
                  if (item.adPosition === '3' && item.isArrive === 1) {
                    this.imageCapsule.push(item)
                  }
                }
              }
            }
          });
    },
    tabClick(val) {
      this.activeName = val;
    },
    toLogin() {
      this.$router.push('/login')
    },
    lookNewsInfo(val) {
      sessionStorage.setItem('detail', JSON.stringify(val))
      this.$router.push('/guideDetail');
    }
  },
};
</script>

<style lang="scss" scoped>

.el-carousel {
  min-width: 1374px !important;
  max-width: 1374px !important;
  height: 160px !important;
  margin: 0 auto !important;
  margin-top: 20px !important;
  line-height: 160px;
}

.bannerItem {
  position: relative;

  img {
    width: 100%;
  }

  .box {
    position: absolute;
    left: .27rem;
    top: .88rem;
    color: #fff;

    p {
      font-size: .23rem;
      line-height: .32rem;
    }

    span {
      font-size: .31rem;
      line-height: .46rem;
    }

    .tips {
      display: flex;
      margin-top: .05rem;

      div {
        padding: 0 .09rem;
        line-height: .24rem;
        border: .01rem solid #81ecff;
        border-radius: .21rem;
        font-size: .1rem;
        color: #81ecff;
        text-align: center;
        margin-right: .07rem;
      }
    }

    .btn {
      width: 1.02rem;
      line-height: .43rem;
      background: #ffffff;
      border-radius: .05rem;
      text-align: center;
      margin-top: .16rem;
      font-size: .14rem;
      color: #2a50b7;
    }
  }
}

.iconsBox {
  text-align: center;
  margin-top: .16rem;

  .title {
    div {
      font-size: .19rem;
      line-height: .27rem;
      color: #444444;
    }

    p {
      width: 1.66rem;
      font-size: .09rem;
      line-height: .12rem;
      color: #666666;
      margin: 0 auto;
      margin-top: .07rem;
    }
  }

  .serverList {
    margin: .24rem .57rem .06rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .items {
      width: .67rem;
      margin-bottom: .12rem;

      div {
        height: .67rem;
        border: .01rem solid #5A9CF8;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: .37rem;
          height: .37rem;
        }
      }

      p {
        font-size: .12rem;
        line-height: .17rem;
        margin-top: .07rem;
      }
    }
  }
}

.iconsBox2 {
  text-align: center;
  margin-top: .16rem;

  .title {
    div {
      margin-top: .16rem;
      font-size: .19rem;
      line-height: .27rem;
      color: #ffffff;
    }

    p {
      width: 1.66rem;
      font-size: .09rem;
      line-height: .12rem;
      color: #ffffff;
      margin: 0 auto;
      margin-top: .07rem;
    }
  }
}

.serve {
  background: #f6f6f6;

  .cardBox {
    text-align: center;
    padding: .19rem 0 .2rem;
    margin: 0 .23rem;

    .title {
      div {
        font-size: .19rem;
        line-height: .27rem;
        color: #444444;
        margin-bottom: .07rem;
      }

      p {
        font-size: .09rem;
        line-height: .12rem;
        color: #666666;
        margin: 0 .56rem;
        margin-bottom: .1rem;
      }
    }

    .cardList {
      margin-top: 0.1rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .cardItem {
        width: 1.57rem;
        background: #ffffff;
        padding: .1rem .16rem .18rem .11rem;
        margin-bottom: .09rem;

        .title {
          position: relative;
          background-repeat: no-repeat;
          background-size: .24rem .24rem;
          background-position: 100% -100%;
          padding-bottom: .09rem;
          border-bottom: .01rem solid #dddddd;

          p {
            font-size: .12rem;
            line-height: .17rem;
            color: #2a50b7;
            margin: 0;
            z-index: 99;
            text-align: left;
          }
        }

        .textBox {
          height: 1.55rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-top: .09rem;

          .text {
            font-size: .1rem;
            line-height: .16rem;
            color: #666666;
            text-align: left;
          }

          .btn {
            width: .78rem;
            line-height: .26rem;
            border: .01rem solid #5A9CF8;
            border-radius: .18rem;
            font-size: .11rem;
            color: #5A9CF8;
            opacity: 0.6;
            margin: 0 auto;
            margin-top: .14rem;
          }
        }
      }
    }
  }
}

.tabsBox {
  .titleBox {
    background: #f6f6f6;
    padding: .16rem .32rem .17rem;

    .title {
      font-size: .11rem;
      font-weight: 600;
      line-height: .14rem;
      color: #666666;
      display: flex;
      justify-content: space-between;

      .active {
        color: #2a50b7;
      }
    }
  }

  .tabsContent {
    padding: .15rem .28rem .2rem;

    img {
      width: 3.19rem;
    }

    div {
      margin-top: .17rem;
      line-height: .14rem;

      p {
        font-size: .11rem;
        color: #2a50b7;
      }

      span {
        font-size: .09rem;
        color: #666666;
      }
    }
  }
}

.line {
  height: .01rem;
  background: #dddddd;
  margin: 0 .22rem;
}

.unit {
  padding: .19rem .15rem .59rem;

  .title {
    text-align: center;

    p {
      font-size: .19rem;
      line-height: .27rem;
      color: #444444;
    }

    span {
      font-size: .09rem;
      line-height: .12rem;
      color: #666666;
      margin-top: .07rem;
    }
  }

  .list {
    display: flex;

    .listItem {
      border-right: .01rem solid #DDDDDD;
      padding: 0 .1rem;

      div {
        width: .96rem;
        margin-bottom: .15rem;
        text-align: center;

        img {
          width: .96rem;
          height: .47rem;
        }

        p,
        span {
          font-size: .08rem;
          line-height: .11rem;
          color: #444444;
        }
      }
    }

    .listItem:last-of-type {
      border: none;
    }
  }
}

#backgroundDiv {
  background-image: url('../assets/home/bigPic.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  padding-bottom: 0.3rem;
  padding-top: 2px;
}

.el-card {
  width: 342px;
  margin: 0 auto;
}

.active-text {
  color: #5A9CF8; /* 激活时的文本颜色为蓝色，根据需要调整 */
}

.active-text2 {
  color: #999999;
}
</style>
