<template>
  <div class="login">
    <img src="../assets/home/login.png" alt="" />
    <p>请使用电脑完成注册或登录访问地址：</p>
    <p>
      <span>{{ url }}</span
      ><span @click="copy">&nbsp;复制</span>
    </p>
  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
  data() {
    return {
      url: "https://platform.mycntrade.com",
    };
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(this.url)
          .then(() => {
            Toast.success('复制成功')
          })
          .catch(() => {
            Toast.success('复制失败')
          })
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 1.16rem;
  height: 1.16rem;
  margin: .73rem auto .53rem;
}
p {
  text-align: center;
  font-size: .16rem;
  line-height: .3rem;
  color: #666;
  span {
    color: #2a50b7;
  }
}
</style>
