<template>
  <div class="guide">
    <div class="tabBox">
      <div class="tabs">
        <div
          :class="item.dictLabel == active ? 'active tabsItem' : 'tabsItem'"
          v-for="(item, index) in tabs"
          :key="index"
          @click="tabClick(item)"
        >
          <div></div>
          <p>{{ item.dictLabel }}</p>
        </div>
      </div>
      <div class="listBox">
        <div class="list" v-for="(item, index) in rightList" :key="index">
          <div class="left" @click="contentClick(item)">
            <img src="../assets/home/icon.png" alt="" />
          </div>
          <div class="right" @click="contentClick(item)">
            <p>{{ item.webTitle }}</p>
            <div style="width:100%;height: 30px;font-size: 12px;">
              <span style="font-size: 12px;">{{ item.newsTime }}</span>
            </div>
            <span>{{ item.introduction }}</span>
          </div>
        </div>
        <van-pagination
          v-model="current"
          :total-items="total"
          v-if="total > 10"
          class="pageStyle"
          @change="handleCurrentChange"
        >
          <template #prev-text>
            <van-icon name="arrow-left" />
          </template>
          <template #next-text>
            <van-icon name="arrow" />
          </template>
          <template #page="{ text }">{{ text }}</template>
        </van-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: "",
      rightList: [],
      total: 0,
      current: 1,
      tabs: [],
      tabValue: "",
    };
  },
  created() {
    this.getTabs();
    sessionStorage.removeItem('detail')
  },
  methods: {
    // getTabs() {
    //   this.$axios
    //     .post("https://platform.mycntrade.com/api/web/business/queryTypes")
    //     .then((res) => {
    //       console.log(res.data);
    //       if (res.data.code == 0) {
    //         this.tabs = res.data.data;
    //         this.active = this.tabs[0].dictLabel;
    //         this.tabValue = this.tabs[0].dictValue;
    //         this.current = 1;
    //         this.getList();
    //       }
    //     });
    // },
    getTabs() {
      this.$axios
          .get("https://news.mycntrade.com/api/zm/queryTypeList")
          .then((res) => {
            console.log(res);
            if (res.data) {
              this.tabs = res.data;
              this.active = this.tabs[0].dictLabel;
              this.tabValue = this.tabs[0].dictValue;
              this.current = 1;
              this.getList();
            }
          });
    },
    // getList() {
    //   let opt = new FormData();
    //   opt.append("typeId", this.tabValue);
    //   opt.append("pageNum", this.current);
    //   opt.append("pageSize", "10");
    //   this.$axios
    //     .post(
    //       "https://platform.mycntrade.com/api/web/business/queryTypeList",
    //       opt
    //     )
    //     .then((res) => {
    //       console.log(res.data);
    //       if (res.data.code == 0) {
    //         this.total = res.data.data.total;
    //         this.rightList = res.data.data.rows;
    //       }
    //     });
    // },
    getList() {
      let opt = new FormData();
      opt.append('type', this.tabValue);
      opt.append('pageNum', this.current);
      opt.append('pageSize', '10');
      this.$axios
          .post("https://news.mycntrade.com/api/zm/queryHomePageList", opt)
          .then((res) => {
            console.log(res);
            if (res.data) {
              this.total = res.data.count;
              this.rightList = JSON.parse(res.data.data);
            }
          });
    },
    tabClick(val) {
      this.active = val.dictLabel;
      this.tabValue = val.dictValue;
      this.current = 1;
      this.getList();
    },
    contentClick(val) {
      console.log(val);
      sessionStorage.setItem('detail',JSON.stringify(val))
      this.$router.push('/guideDetail');
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.tabBox {
  padding: 0.61rem 0.26rem 0.5rem 0.04rem;
  padding-top: 0.61rem;
  display: flex;
  min-height: calc(100vh - 2.96rem);
}
.tabs {
  .tabsItem {
    margin-bottom: 0.09rem;
    width: 0.84rem;
    height: 0.35rem;
    display: flex;
    align-items: center;
    div {
      width: 0.07rem;
      height: 0.35rem;
      background: #ddd;
    }
    p {
      font-size: 0.11rem;
      line-height: 0.29rem;
      color: #666666;
      margin-left: 0.14rem;
    }
  }
  .active {
    background: #f6f6f6;
    div {
      background: #2a50b7;
    }
  }
}
.listBox {
  .list {
    margin-left: 0.04rem;
    display: flex;
    padding-bottom: 0.15rem;
    border-bottom: 0.01rem solid #dddddd;
    margin-bottom: 15px;
    .left {
      img {
        width: 0.53rem;
        height: 0.53rem;
      }
    }
    .right {
      p {
        font-size: 0.12rem;
        line-height: 20px;
        color: #2a50b7;
        margin-top: 1px;
      }
      span {
        font-size: 0.11rem;
        line-height: 0.18rem;
        color: #999999;
        overflow: hidden;
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }
    }
  }
}
::v-deep .pageStyle {
  margin-top: 0.1rem;
  .van-pagination__next,
  .van-pagination__prev {
    padding: 0 0.04rem;
  }
  .van-pagination__item {
    min-width: 0.36rem;
    height: 0.4rem;
  }
  .van-icon {
    font-size: 0.14rem;
  }
}

.van-pagination {
  font-size: 0.14rem;
}
</style>
